import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundImage from "../components/bgImage"

const Consulting = () => {
  return (
    <Layout>
      <SEO
        title="Customer Acquisition"
        description=" Customer Acquisition is at the heart of almost every business today
          and is vital to establishing market share."
      />
      <div className="consulting-wrapper wrapper py-5">
        <BackgroundImage src="customer-acquisition.png">
          <div className="consulting-banner" />
        </BackgroundImage>
        <h1 className="mt-3">CUSTOMER ACQUISITION</h1>
        <p>
          Customer Acquisition is at the heart of almost every business today
          and is vital to establishing market share. Generating leads is a
          highly effective way to promote your product or service, and establish
          relationships with potential customers right before they are ready to
          buy.
        </p>
        <p>
          Through precise demographic targeting, Intermedia Marketing Group
          helps clients place ads in front of prospective customers. Designing
          ads that generate interest and demand is part of our secret sauce, but
          after that, we go to work placing them across multiple channels.
        </p>
        <ul>
          <li>CPM Banner Ad Networks</li>
          <li>Search Engine Marketing (SEM)</li>
          <li>Affiliate & Agency Partners</li>
          <li>Social Media Placements</li>
          <li>Direct Site Buy</li>
          <li>Pay Per Call</li>
        </ul>
      </div>
    </Layout>
  )
}

export default Consulting
